<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col cols="12" v-show="this.contentDashboard1 ? true : false">
                <v-card class="p-3">
                    <h3 style="font-weight: 900;">CGL1 
                        <v-btn
                            class="mr-2 text-right"
                            color="primary"
                            elevation="2"
                            small
                            rounded
                            @click="showDialog()"
                        >
                            <v-icon dark>
                                mdi-magnify
                            </v-icon>
                            Order ID
                        </v-btn>
                    </h3>

                    <v-card-text class="blue lighten-5">
                        <h6 style="font-weight: 600;" class="blue-text text-darken-3 m-0"><i class="material-icons">album</i> {{this.contentDashboard1 ? this.contentDashboard1.sched_id : 'Pls Wait'}} ({{this.contentDashboard1 ? this.contentDashboard1.dt_sched : 'Pls Wait'}})</h6>
                        <v-card-subtitle class="p-1">{{this.contentDashboard1 ? this.contentDashboard1.dt_awal : 'Pls Wait'}} - {{this.contentDashboard1 ? this.contentDashboard1.dt_akhir : 'Pls Wait'}}</v-card-subtitle>
                        
                    </v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-sheet
                                class="mx-auto mt-lg-8 mb-lg-3"
                                v-if="this.contentDashboard1"
                            >
                                <v-slide-group
                                multiple
                                show-arrows
                                >
                                <v-slide-item
                                    v-for="(liststep1, i) in this.contentDashboard.liststep1"
                                    :key="i"
                                >
                                    <v-btn
                                        class="mx-2"
                                        active-class="green white--text"
                                        depressed
                                        :input-value="liststep1.stat === 'C' ? true : false"
                                        rounded
                                        @click="getDetailCgl1(liststep1)"
                                    >
                                        Step {{ liststep1.step_num }}
                                        <v-badge 
                                            :color="liststep1.stat === 'C' ? 'primary' : 'secondary'"
                                            :content="liststep1.stat"
                                            class="ml-3"
                                        >
                                        </v-badge>
                                    </v-btn>                                
                                </v-slide-item>
                                </v-slide-group>
                            </v-sheet>
                        </v-col>
                        <v-col cols="12">
                            <v-list dense class="p-2">
                                <v-list-item-group
                                    v-model="selectedItem"
                                    color="primary"
                                >
                                    <v-list-item
                                    v-for="(item, i) in header_detail1"
                                    :key="i"
                                    >
                                    <v-list-item-icon>
                                        <v-icon>mdi-clock</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>STEP {{firstdetail1}}, SEQ {{item.seq_num}} ( {{$store.getters.convertToCurrency(item.thick)}} x {{item.width}} )</v-list-item-title>
                                        <v-simple-table
                                            fixed-header
                                            max-height="300px"
                                            class="mt-5"
                                        >
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        ProdCode
                                                    </th>
                                                    <th class="text-left">
                                                        Descr
                                                    </th>
                                                    <th class="text-left">
                                                        CustName
                                                    </th>
                                                    <th class="text-left">
                                                        OrderID
                                                    </th>
                                                    <th class="text-left">
                                                        WgtOrder
                                                    </th>
                                                    <th class="text-left">
                                                        WgtPlan
                                                    </th>
                                                    <th class="text-left">
                                                        WgtProd
                                                    </th>
                                                    <th class="text-left">
                                                        Dest
                                                    </th>
                                                    <th class="text-left">
                                                        SCStat
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(detail, y) in item.detail"
                                                    :key="y"
                                                >
                                                    <td>{{ detail.prod_code }}</td>
                                                    <td>{{ detail.descr }}</td>
                                                    <td>{{ detail.cust_name }}</td>
                                                    <td>{{ detail.order_id }}</td>
                                                    <td>{{ $store.getters.convertToCurrency(detail.wgt_ord/1000) }}</td>
                                                    <td>{{ $store.getters.convertToCurrency(detail.wgt_plan/1000) }}</td>
                                                    <td>{{ $store.getters.convertToCurrency(detail.wgt_prod/1000) }}</td>
                                                    <td>{{ detail.dest }}</td>
                                                    <td>{{ detail.sc_stat }}</td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" v-show="this.contentDashboard2 ? true : false">
                <v-card class="p-3 bg_card">
                    <h3 style="font-weight: 900;">CGL2
                    </h3>

                    <v-card-text class="white">
                        <h6 style="font-weight: 600;" class="blue-text text-darken-3 m-0"><i class="material-icons">album</i> {{this.contentDashboard2 ? this.contentDashboard2.sched_id : 'Pls Wait'}} ({{this.contentDashboard2 ? this.contentDashboard2.dt_sched : 'Pls Wait'}})</h6>
                        <v-card-subtitle class="p-1">{{this.contentDashboard2 ? this.contentDashboard2.dt_awal : 'Pls Wait'}} - {{this.contentDashboard2 ? this.contentDashboard2.dt_akhir : 'Pls Wait'}}</v-card-subtitle>
                        
                    </v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-sheet
                                class="mx-auto mt-lg-8 mb-lg-3"
                                v-if="this.contentDashboard"
                            >
                                <v-slide-group
                                multiple
                                show-arrows
                               
                                >
                                <v-slide-item
                                    v-for="(liststep2, i) in this.contentDashboard.liststep2"
                                    :key="i"
                                >
                                    <v-btn
                                        class="mx-2"
                                        active-class="green white--text"
                                        depressed
                                        :input-value="liststep2.stat === 'C' ? true : false"
                                        rounded
                                        @click="getDetailCgl2(liststep2)"
                                    >
                                        Step {{ liststep2.step_num }}
                                        <v-badge 
                                            :color="liststep2.stat === 'C' ? 'primary' : 'secondary'"
                                            :content="liststep2.stat"
                                            class="ml-3"
                                        >
                                        </v-badge>
                                    </v-btn>                                
                                </v-slide-item>
                                </v-slide-group>
                            </v-sheet>
                        </v-col>
                        <v-col cols="12">
                            <v-list dense class="p-2">
                                <v-list-item-group
                                    v-model="selectedItem2"
                                    color="primary"
                                >
                                    <v-list-item
                                    v-for="(item, i) in header_detail2"
                                    :key="i"
                                    >
                                    <v-list-item-icon>
                                        <v-icon>mdi-clock</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>STEP {{firstdetail2}}, SEQ {{item.seq_num}} ( {{$store.getters.convertToCurrency(item.thick)}} x {{item.width}} )</v-list-item-title>
                                        <v-simple-table
                                            fixed-header
                                            max-height="300px"
                                            class="mt-5"
                                        >
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        ProdCode
                                                    </th>
                                                    <th class="text-left">
                                                        Descr
                                                    </th>
                                                    <th class="text-left">
                                                        CustName
                                                    </th>
                                                    <th class="text-left">
                                                        OrderID
                                                    </th>
                                                    <th class="text-left">
                                                        WgtOrder
                                                    </th>
                                                    <th class="text-left">
                                                        WgtPlan
                                                    </th>
                                                    <th class="text-left">
                                                        WgtProd
                                                    </th>
                                                    <th class="text-left">
                                                        Dest
                                                    </th>
                                                    <th class="text-left">
                                                        SCStat
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(detail, y) in item.detail"
                                                    :key="y"
                                                >
                                                    <td>{{ detail.prod_code }}</td>
                                                    <td>{{ detail.descr }}</td>
                                                    <td>{{ detail.cust_name }}</td>
                                                    <td>{{ detail.order_id }}</td>
                                                    <td>{{ $store.getters.convertToCurrency(detail.wgt_ord/1000) }}</td>
                                                    <td>{{ $store.getters.convertToCurrency(detail.wgt_plan/1000) }}</td>
                                                    <td>{{ $store.getters.convertToCurrency(detail.wgt_prod/1000) }}</td>
                                                    <td>{{ detail.dest }}</td>
                                                    <td>{{ detail.sc_stat }}</td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
        v-model="dialog"
        persistent
        max-width="400px"
        >
        <v-card>
            <v-card-title>
            <span class="text-h5">Search By Order ID</span>
            </v-card-title>
            <v-card-text>
            <v-container fluid>
                <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                    >
                        <v-text-field
                            label="Order ID*"
                            v-model="order_id"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
                Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="applyOrderID()"
            >
                Apply
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
  export default {
    data() {
        return {
            model: null,
            order_id: '',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Dashboard',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Block Schedule',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            tab: 'tab-1',
            speed: null,
            dashboardContent: null,
            x: '',
            e1: 1,
            steps: 40,
            selectedItem: '',
            selectedItem2: '',
            items: [
                { text: 'STEP 10, SEQ 1 (0,35 x 914)', icon: 'mdi-clock' }
            ],
            contentDashboard: null,
            contentDashboard1: null,
            contentDashboard2: null,
            header_detail1: [],
            firstdetail1: '',
            header_detail2: [],
            firstdetail2: '',
            dialog: false
        }
    },
    mounted(){
        this.getDashboard()
    },
    methods:{
        async getDashboard(){
            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/sr/block-schedule?orderid=${this.order_id ? this.order_id : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                console.log(res.data);
                this.$store.dispatch('setOverlay', false)
                this.contentDashboard = res.data
                this.contentDashboard1 = res.data.header1
                this.contentDashboard2 = res.data.header2

                this.header_detail1 = res.data.header_detail1
                this.firstdetail1 = res.data.firstdetail1
                this.header_detail2 = res.data.header_detail2
                this.firstdetail2 = res.data.firstdetail2
            })
        },
        async getDetailCgl1(item){           
            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/sr/block-schedule/detail1?sched_id_1=${item.sched_id}&stepnum1=${item.step_num}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.firstdetail1 = item.step_num
                this.$store.dispatch('setOverlay', false)
                this.header_detail1 = res.data.detailStep1
            })
        },
        async getDetailCgl2(item){           
            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/sr/block-schedule/detail2?sched_id_2=${item.sched_id}&stepnum2=${item.step_num}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.firstdetail2 = item.step_num
                this.$store.dispatch('setOverlay', false)
                this.header_detail2 = res.data.detailStep2
            })
        },
        showDialog(){
            this.dialog = true
        },
        applyOrderID(){
            this.dialog = false

            this.getDashboard()
        }
    },
    watch: {
    },
  }
</script>
<style>
.v-slide-group__content {
    display: flex;
    flex: 1 0 auto;
    background-color: #e3f2fd;
    position: relative;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    white-space: nowrap;
}
</style>